.contact-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.contact-hero {
  grid-column: span 2;
  background-color: #f8f8f8;
  padding: 30px;
  text-align: center;
}

.contact-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.contact-info, .contact-map {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.map-container iframe {
  width: 100%;
  border: none;
  border-radius: 8px;
  height: 100%;
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-container {
    grid-template-columns: 1fr;
  }

  .contact-hero {
    grid-column: span 1;
    padding: 20px;
  }

  .contact-details {
    grid-template-columns: 1fr;
  }

  .contact-info, .contact-map {
    padding: 15px;
  }

  .map-container iframe {
    height: 300px; /* Adjust the height for smaller screens */
  }
}
