.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: transparent;
  color: black !important;
  flex-wrap: wrap;
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

.menu-icon {
  display: none; /* Hide by default */
  font-size: 24px;
  cursor: pointer;
  color: black; /* Adjust as needed */
}

.nav {
  display: flex;
  flex-direction: row;
}

.nav-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
}

.nav-item {
  position: relative;
  margin-left: 20px;
}

.nav-link {
  color: rgb(2, 2, 2);
  text-decoration: none;
  padding: 10px 15px;
  transition: background-color 0.3s;
}

.nav-link:hover,
.active-link {
  color: black;
  background-color: white;
  border-radius: 4px;
}

.dropdown-menu {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  color: #000;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 200px;
}

.dropdown-link {
  color: #000;
  text-decoration: none;
  display: block;
  padding: 10px;
  transition: background 0.3s;
}

.dropdown-link:hover {
  background: #f0f0f0;
}

.logo img {
  width: 130px;
  height: auto;
}

.logo a:last-child {
  color: #000;
  text-decoration: none;
  font-size: 20px;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .nav {
    display: none; /* Hide the nav links by default on smaller screens */
    flex-direction: column; /* Stack nav items vertically on small screens */
    width: 100%;
  }

  .nav-open {
    display: flex; /* Show the nav links when the menu is open */
  }

  .nav-list {
    flex-direction: column; /* Stack nav items vertically */
    align-items: center;
  }

  .nav-item {
    margin-left: 0;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
  }

  .nav-link {
    padding: 10px;
    width: 100%;
    text-align: center;
  }

  .dropdown-menu {
    position: static;
    width: 100%;
    box-shadow: none;
  }

  .dropdown-link {
    text-align: center;
  }

  .menu-icon {
    display: block; /* Show the menu icon on smaller screens */
  }
}
