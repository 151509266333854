.footer {
  background: linear-gradient(to right, black, #001133);
  color: white;
  padding: 20px 0;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-logo img {
  width: 150px;
}

.footer-links,
.footer-contact,
.footer-social {
  margin: 20px 0;
  flex: 1;
  min-width: 200px;
  text-align: center; /* Center-align content for smaller screens */
}

.footer-links h3,
.footer-contact h3,
.footer-social h3 {
  margin-bottom: 10px;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #dc3545; /* Matches the text-danger class */
}

.footer-links ul,
.footer-social ul {
  list-style: none;
  padding: 0;
}

.footer-links li,
.footer-social li {
  margin-bottom: 10px;
}

.footer-links a,
.footer-contact a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-links a:hover,
.footer-contact a:hover {
  color: #ddd;
}

.footer-contact p {
  margin: 5px 0;
}

.footer-social ul {
  display: flex;
  justify-content: center; /* Center-align social icons */
  gap: 10px;
}

.footer-social a {
  color: #fff;
  font-size: 1.5rem;
  transition: color 0.3s;
}

.footer-social a:hover {
  color: #ddd;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #444;
}

.footer-bottom p {
  margin: 0;
  font-size: 0.9rem;
}

/* Adjustments for screens 768px or smaller */
@media (max-width: 768px) {
  .footer-container {
      flex-direction: column;
      align-items: center;
      padding: 0 10px; /* Reduce padding for smaller screens */
  }

  .footer-links,
  .footer-contact,
  .footer-social {
      margin: 10px 0;
      text-align: center;
  }

  .footer-logo img {
      width: 120px; /* Reduce logo size for smaller screens */
  }

  .footer-links h3,
  .footer-contact h3,
  .footer-social h3 {
      font-size: 1rem; /* Adjust heading font size for smaller screens */
  }

  .footer-social ul {
      gap: 8px; /* Reduce gap between social icons */
  }

  .footer-social a {
      font-size: 1.2rem; /* Adjust icon size for smaller screens */
  }
}

/* Further adjustments for screens 480px or smaller */
@media (max-width: 480px) {
  .footer-container {
      padding: 0 5px; /* Further reduce padding for very small screens */
  }

  .footer-links,
  .footer-contact,
  .footer-social {
      min-width: 100%; /* Ensure full-width blocks on small screens */
      margin: 10px 0;
  }

  .footer-links li,
  .footer-social li {
      margin-bottom: 8px; /* Reduce margin between items */
  }

  .footer-links a,
  .footer-contact a {
      font-size: 0.9rem; /* Adjust link font size */
  }

  .footer-bottom p {
      font-size: 0.8rem; /* Adjust bottom text size */
  }
}
