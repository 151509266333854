/* Enhance the carousel container */
.carousel-container {
  max-width: 70%; /* Adjust width as needed */
  margin: 0 auto; /* Center the carousel */
  margin-bottom: 30px; /* Space below the carousel */
}

/* Enhanced Carousel */
.enhanced-carousel {
  max-height: 700px; /* Increased height for a more prominent display */
  overflow: hidden;  /* Hide overflow content */
  border-radius: 15px; /* Rounded corners for a sleek look */
}
.enhanced-carousel .carousel-item img {
  object-fit: cover; /* Ensure the image covers the area without distortion */
  height: 100%; /* Set height for the image */
  border-radius: 15px; /* Match the border radius */
}

/* Carousel Caption */
.carousel-caption {
  bottom: 20px; /* Position caption near the bottom */
}

.carousel-caption-text {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  height: 60px; /* Adjust height as needed */
  display: flex;
  align-items: center;
}

.caption-line {
  display: inline-block;
  white-space: nowrap;
  font-size: 1.5rem; /* Adjust font size as needed */
  color: #fff; /* Font color */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6); /* Text shadow for better visibility */
  animation: moveText 10s linear infinite; /* Move animation */
  line-height: 1.5; /* Line height to handle multiple lines */
}

.caption-line.highlighted {
  font-weight: bold; /* Highlighted text style */
  font-size: 1.8rem; /* Larger font size for highlighted text */
  color: #f5c518; /* Highlight color */
}

@keyframes moveText {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.carousel-caption-text .caption-line.highlighted {
  animation: moveText 10s linear infinite;
}

.carousel-caption-text .caption-line {
  animation: moveText 10s linear infinite;
  animation-delay: 5s; /* Delay to start the second text animation */
}
.swiper {
  width: 80%;
  height: 700px;
  border-radius: 20px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-slide-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.swiper-slide-content img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  background:transparent;
  padding: 10px;
  border-radius: 10px;
}

.caption h2 {
  margin: 0;
  font-size: 2rem;
}

.caption p {
  margin: 0;
  font-size: 1.2rem;
}

